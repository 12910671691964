<template>
  <div class="staff-item">
    <div class="font-weight-bold">
      <router-link class="disabled--text" to="/staff">Staff</router-link>
      <v-icon color="disabled" class="text-h6">mdi-chevron-right</v-icon><span>{{ data.first_name }} {{ data.last_name }}</span>
    </div>
    <v-card class="mt-4" width="100%" outlined>
      <div class="d-flex wrap">
        <v-card color="item-menu pb-2" flat>
          <div class="py-8 px-4 d-flex">
            <v-avatar color="gray lighten-2" size="52">
              <v-img v-if="data.picture != null" :src="data.picture"></v-img>
              <div v-else class="d-flex align-center">
                <v-img class="gray-filter" contain width="36" height="36" src="@/assets/icon/user.svg"></v-img>
              </div>
            </v-avatar>
            <div class="ms-3">
              <div class="text-h6 font-weight-bold">{{ data.first_name }} {{ data.last_name }}</div>
              <div class="text--text text-capitalize">{{ data.role ? data.role.split('_').join(' ') : '' }}</div>
            </div>
          </div>
          <div
            v-for="(item, i) in menuItems"
            :key="item"
            :class="link == i + 1 ? 'gray lighten-4 pl-3 pr-5' : 'px-5'"
            @click="link = i + 1"
            class="item-menu_item link"
          >
            <div>{{ item }}</div>
          </div>
        </v-card>
        <v-divider style="z-index: 1" vertical></v-divider>
        <div class="width100">
          <v-expand-transition>
            <div v-if="link == 1">
              <Name />
            </div>
          </v-expand-transition>
          <v-expand-transition>
            <div v-if="link == 2">
              <Security />
            </div>
          </v-expand-transition>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import Name from '@/components/staff/Name';
import Security from '@/components/staff/Security';

export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  components: {
    Name,
    Security,
  },
  data() {
    return {
      link: 1,
      menuItems: ['Account', 'Security'],
    };
  },
  mounted() {
    this.$store.dispatch('getStaffItem');
    this.$store.dispatch('getTripOrganizers');
    this.$store.dispatch('getVendorsShort');
  },
  computed: {
    data() {
      return this.$store.getters.staff;
    },
  },
  destroyed() {
    this.$store.dispatch('setStaffItem', {});
  },
};
</script>
