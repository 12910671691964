<template>
  <div class="staff-security">
    <div class="item-content d-flex align-center flex-sm-nowrap flex-wrap pl-lg-9 pa-sm-7 pa-3">
      <v-card max-width="500" tile flat class="me-4">
        <div class="text-h6 font-weight-bold">Change password</div>
        <div class="text--text mt-2">You can reset user password. It will be sent to the Staff email.</div>
      </v-card>
      <v-spacer></v-spacer>
      <v-btn @click="modal = true" class="my-3" depressed large width="176" color="primary">Reset</v-btn>
    </div>
    <v-divider></v-divider>
    <div class="item-content pl-lg-9 pa-sm-7 pa-3">
      <div class="text-h6 font-weight-bold">Two factor authentication</div>
      <div class="d-flex align-center mt-2">
        <v-card flat rounded="circle" :class="data['2fa_enabled'] ? 'success' : 'error'" width="12" height="12"></v-card>
        <div class="text--text ms-3">{{ data['2fa_enabled'] ? 'Enable' : 'Disable' }}</div>
      </div>
    </div>
    <v-dialog v-model="modal" max-width="480">
      <v-card flat outlined class="pb-6 px-4">
        <div class="d-flex mt-3">
          <div class="text-h6 font-weight-bold">Do you really want to reset the staff password?</div>
          <v-btn icon @click="modal = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <div class="text-end mt-8">
          <v-btn @click="resetPass" depressed width="106" height="30" class="primary text-body-2">Confirm</v-btn>
          <v-btn @click="modal = false" outlined class="ms-3 text-body-2" width="106" height="30">Cancel</v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      modal: false,
      error: [],
    };
  },
  methods: {
    notifi(btn) {
      if (btn == 'done') {
        this.$notify({
          title: 'Done!',
          message: 'Email with new password was sent successfully',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (this.error.find((item) => item == 'password_reset__too_often')) {
        this.$notify({
          title: 'Warning!',
          message: 'Sorry, but email with new password requests limit exceeded. Try again later',
          type: 'warning',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    async resetPass() {
      this.error = [];
      await this.$store
        .dispatch('resetPassStaff')
        .then(() => {
          this.modal = false;
          this.notifi('done');
        })
        .catch((e) => {
          this.error = e.response.data.error;
          this.notifi();
        });
    },
  },
  computed: {
    data() {
      return this.$store.getters.staff;
    },
  },
};
</script>
