<template>
  <div class="staff-name">
    <div v-if="data.role" :class="data.is_email_confirmed ? 'd-none' : 'd-flex'" class="align-center msg">
      <v-icon color="warning">mdi-alert-circle-outline</v-icon>
      <div class="ml-4 text-body-2">Email is not confirmed</div>
      <v-spacer></v-spacer>
      <v-btn @click="resend" outlined width="116">Resend</v-btn>
    </div>
    <div class="item-content d-flex flex-md-nowrap flex-wrap">
      <v-card tile flat max-width="460" class="pl-lg-9 pa-sm-7 pa-3 width100">
        <div class="text-h6 font-weight-bold mb-7">Account details</div>
        <v-text-field
          class="field46"
          v-model="data.first_name"
          placeholder="First name"
          :error-messages="firstNameErrors"
          outlined
          dense
          required
          color="primary"
          height="46"
        ></v-text-field>
        <v-text-field
          class="field46 mt-n2"
          v-model="data.last_name"
          placeholder="Last name"
          :error-messages="lastNameErrors"
          outlined
          dense
          required
          color="primary"
          height="46"
        ></v-text-field>
        <v-text-field
          class="field46 mt-n2"
          v-model="data.email"
          placeholder="Email"
          :error-messages="emailErrors"
          outlined
          dense
          required
          color="primary"
          height="46"
        ></v-text-field>
        <v-select
          class="field46 mt-n2"
          v-model="data.role"
          :items="itemsRole"
          item-text="title"
          item-value="id"
          placeholder="Role"
          :error-messages="roleErrors"
          outlined
          dense
          required
          color="primary"
          height="46"
        ></v-select>
        <v-autocomplete
          v-if="data.role == 'trip_organizer'"
          class="field46 mt-n2"
          v-model="data.trip_organizer"
          placeholder="Select trip organizer"
          :error-messages="orgErrors"
          :items="itemsOrganiz"
          item-text="organizer.name"
          item-value="id"
          return-object
          outlined
          dense
          required
          color="primary"
          height="46"
        ></v-autocomplete>
        <v-autocomplete
          v-if="data.role == 'product_vendor'"
          class="field46 mt-n2"
          v-model="data.product_vendor"
          placeholder="Select product vendor"
          :error-messages="vendorErrors"
          :items="itemsVendors"
          item-text="title_en"
          item-value="id"
          return-object
          outlined
          dense
          required
          color="primary"
          height="46"
        ></v-autocomplete>
        <div>
          <v-btn @click="updateStaff" width="176" large depressed color="primary">Update</v-btn>
        </div>
      </v-card>
      <div class="ml-auto mr-lg-0 mr-auto px-5 mt-7">
        <div class="text-h6 font-weight-bold mb-5">Staff photo</div>
        <div class="link" @click="changeAvatar">
          <v-card v-if="!data.picture && !image" class="foto-card d-flex align-center" flat width="174" height="174">
            <v-img class="gray-filter" contain height="120" src="@/assets/icon/user.svg"></v-img>
          </v-card>
          <v-img cover v-else height="174" max-width="174" :src="image || data.picture"></v-img>
        </div>
        <v-btn :disabled="!file" @click="updatePhote" outlined width="176" large class="d-block text-body-2 mx-auto mt-6">
          Upload Photo
        </v-btn>
        <v-btn
          :disabled="!data.picture"
          @click="deletePhote"
          text
          width="176"
          large
          class="d-block text-body-2 mx-auto mt-1 mb-5"
          color="primary"
        >
          Remove Photo
        </v-btn>
      </div>
    </div>
    <v-divider></v-divider>
    <v-card tile flat max-width="500" class="pl-lg-9 pa-sm-7 pa-3">
      <div class="text-h6 font-weight-bold mb-1">Account status</div>
      <div class="text--text">You can deactivate the account to restrict its access to the platform.</div>
      <v-checkbox
        @click="!data.is_suspended ? deactivate() : activate()"
        color="success"
        :input-value="!data.is_suspended"
        value
        label="Account active"
      >
      </v-checkbox>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      file: '',
      image: '',
      itemsRole: [
        { id: 'admin', title: 'Admin' },
        { id: 'manager', title: 'Manager' },
        { id: 'marketing', title: 'Marketing' },
        { id: 'agent', title: 'Agent' },
        { id: 'trip_organizer', title: 'Trip organizer' },
        { id: 'product_vendor', title: 'Product vendor' },
      ],
      error: [],
    };
  },
  methods: {
    notifi(btn) {
      if (btn == 'confirm') {
        this.$notify({
          title: 'Done!',
          message: 'Email confirmation was sent successfully',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == 'activate') {
        this.$notify({
          title: 'Done!',
          message: 'Staff profile was successfully activate',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == 'deactivate') {
        this.$notify({
          title: 'Done!',
          message: 'Staff profile was successfully deactivate',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == 'updatePhoto') {
        this.$notify({
          title: 'Done!',
          message: 'Staff profile picture was successfully updated',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == 'update') {
        this.$notify({
          title: 'Done!',
          message: 'Staff profile was successfully updated',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (this.error.find((item) => item == 'confirmation__too_often')) {
        this.$notify({
          title: 'Warning!',
          message: 'Sorry, but email confirmation requests limit exceeded. Try again later',
          type: 'warning',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (this.error.find((item) => item == 'email__already_confirmed')) {
        this.$notify({
          title: 'Warning!',
          message: 'Email is already confirmed',
          type: 'warning',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    changeAvatar() {
      const input = document.createElement('input');
      const self = this;
      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'image/*');
      input.addEventListener('change', function(e) {
        if (this.files && this.files[0]) {
          self.file = this.files[0];
          var reader = new FileReader();
          reader.onload = (e) => {
            self.image = e.target.result;
          };
          reader.readAsDataURL(this.files[0]);
        }
      });
      input.click();
    },
    async updatePhote() {
      const formData = new FormData();
      formData.append('picture', this.file);
      await this.$store.dispatch('setPhotoStaff', formData).then(() => {
        this.file = '';
        this.image = '';
        this.notifi('updatePhoto');
      });
    },
    async deletePhote() {
      await this.$store.dispatch('deletePhotoStaff').then(() => {
        this.file = '';
        this.image = '';
        this.$store.dispatch('getStaffItem');
      });
    },
    async updateStaff() {
      this.error = [];
      const data = new Object();
      data.first_name = this.data.first_name || '';
      data.last_name = this.data.last_name || '';
      data.email = this.data.email || '';
      data.role = this.data.role || '';
      this.data.role == 'trip_organizer' ? (data.trip_organizer = this.data.trip_organizer.id) : '';
      this.data.role == 'product_vendor' ? (data.product_vendor = this.data.product_vendor.id) : '';
      await this.$store
        .dispatch('updateStaffItem', data)
        .then(() => {
          this.notifi('update');
        })
        .catch((e) => {
          this.error = e.response.data.error;
        });
    },
    async resend() {
      await this.$store
        .dispatch('sendEmailStaff')
        .then(() => {
          this.notifi('confirm');
        })
        .catch((e) => {
          this.error = e.response.data.error;
          this.notifi();
        });
    },
    async activate() {
      await this.$store.dispatch('activateStaff').then(() => {
        this.notifi('activate');
      });
    },
    async deactivate() {
      await this.$store.dispatch('deactivateStaff').then(() => {
        this.notifi('deactivate');
      });
    },
  },
  computed: {
    data() {
      return this.$store.getters.staff;
    },
    itemsOrganiz() {
      return this.$store.getters.tripOrganiz;
    },
    itemsVendors() {
      return this.$store.getters.vendorsShort;
    },
    firstNameErrors() {
      const errors = [];
      this.error.find((item) => item == 'first_name__required') && errors.push('Please enter your first name');
      this.error.find((item) => item == 'first_name__invalid') && errors.push('Provided first name is not valid');
      return errors;
    },
    lastNameErrors() {
      const errors = [];
      this.error.find((item) => item == 'last_name__required') && errors.push('Please enter your last name');
      this.error.find((item) => item == 'last_name__invalid') && errors.push('Provided last name is not valid');
      return errors;
    },
    emailErrors() {
      const errors = [];
      this.error.find((item) => item == 'email__required') && errors.push('Please enter your email address');
      this.error.find((item) => item == 'email__invalid') && errors.push('Provided email address is not valid');
      this.error.find((item) => item == 'email__exists') &&
        errors.push('Provided email address is already registered. Please use another one');
      this.error.find((item) => item == 'email__update_too_often') &&
        errors.push('Sorry, but you are trying to update email too often. Please try again later');
      return errors;
    },
    roleErrors() {
      const errors = [];
      this.error.find((item) => item == 'role__required') && errors.push('Please enter role');
      this.error.find((item) => item == 'role__invalid') && errors.push('Provided role is not valid');
      return errors;
    },
    orgErrors() {
      const errors = [];
      this.error.find((item) => item == 'organizer__required') && errors.push('Please select trip organizer');
      this.error.find((item) => item == 'organizer__invalid') && errors.push('Selected trip organizer is not valid');
      return errors;
    },
    vendorErrors() {
      const errors = [];
      this.error.find((item) => item == 'product_vendor__required') && errors.push('Please select product vendor');
      this.error.find((item) => item == 'product_vendor__invalid') && errors.push('Selected product vendor is not valid');
      return errors;
    },
  },
};
</script>

<style lang="scss">
.staff-name {
  .msg {
    width: 100%;
    padding: 12px 30px;
    background: var(--v-warning-lighten5);
    border: 1px solid var(--v-warning-base);
    margin-left: 1px;
    .v-btn {
      background: white;
      .v-btn__content {
        font-size: 14px !important;
      }
    }
  }
  .v-label {
    color: black !important;
  }
}
</style>
